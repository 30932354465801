import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../../../components/Layout';

const HouseStakeouts = () => (
  <Layout
    title="House Stakeouts Calgary & Red Deer | Axiom Geomatics"
    description="A House Stakeout is a service that accurately identifies the location of building construction as well as the depth to excavate."
    keywords="house stakeout, house survey, layout, location, excavation layout, building a new home, municipal survey, construction survey, excavation survey, basement stakeout, house development"
  >
    <main>
      <h1>House Stakeouts Calgary & Area</h1>
      <p>
        A House/Garage/Condo Stakeout involves the accurate placement of colored construction stakes
        (survey markers) according to the municipal-approved{' '}
        <a href="https://axiomgeomatics.ca/services/construction-services/plot-plans">Plot Plan</a>{' '}
        so that the excavation service knows exactly where and how deep to dig the basement.
      </p>

      <p>
        A <strong>Stakeout</strong> is the immediate precursor to breaking ground on a construction
        project such as your new home/garage/condo/driveway/etc…
      </p>

      <p>
        Having an <strong>Alberta Land Surveyor</strong> layout where your new structure will be has
        the benefit of starting the actual construction phase of your project off on a path for
        success. The alternative may be expensive: possible errors include having to demolish a
        (partially-built or complete) structure to be compliant with by-laws, intrusion upon utility
        right-of-ways and the cost of additional surveys and easements.
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#pin-footings">Does a Surveyor need to pin footings/walls?</Link>
        </li>
        <li>
          <Link to="#stakeout-by-als">
            Do I need an certified Alberta Land Surveyor to perform the stakeout?
          </Link>
        </li>
      </ul>

      <h3 id="pin-footings">Does a Surveyor need to pin footings/walls?</h3>
      <p>
        Possibly not. If the structure being built is simple (e.g. 6 or fewer right-angle corners)
        than the cribber may be able to layout the foundation from the Surveyor's existing marks.
        For complex structures (those with 8+ corners or non-right-angle corners) a Surveyor should
        be the person ensuring the foundation's accuracy.
      </p>

      <h3 id="stakeout-by-als">
        Do I need an certified Alberta Land Surveyor to perform the stakeout?
      </h3>
      <p>
        As buildings are located in relation to property lines, only an Alberta Land Surveyor may
        undertake this work in Alberta.
      </p>
    </main>
  </Layout>
);

export default HouseStakeouts;
